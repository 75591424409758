import React from 'react'
import { PageProps } from 'gatsby'
import { LandingPage } from '@/app/organisms/landingPage/landingPage'
import { SEO } from '@/app/organisms/layout/components/seo'

const Home: React.FC<PageProps> = () => {
  return (
    <>
      <SEO />
      <LandingPage />
    </>
  )
}

export default Home
