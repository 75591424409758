import React, { useContext } from 'react'
import { motion, useAnimation } from 'framer-motion'
import styles from './styles'
import { useWindowSize } from '@/hooks/useWindowSize'
import { LayoutContext } from '@/app/organisms/layout/layout'
import useResizeObserver from 'use-resize-observer'

export const ParalaxSlider = (props: { children: React.ReactNode }) => {
  const {ref, width} = useResizeObserver<HTMLDivElement>()
  const window = useWindowSize()
  const { isMobile } = useContext(LayoutContext)
  const titleSize = isMobile
    ? window.width
    : typeof window.width === 'number' && window.width - 420
  const controls = useAnimation()

  const { children } = props

  controls.start({
    x: isMobile && width
      ? typeof window.width === 'number'
        ? -width + window.width
        : -width
      : typeof titleSize === 'number'
      ? width && -width + titleSize
      : width && -width,
    transition: {
      duration: width && width / 20,
      repeat: Infinity,
      repeatType: 'reverse',
      type: 'tween',
    },
  })

  return (
    <motion.div ref={ref} css={styles.paralaxSliderContainer}>
      <motion.div
        css={styles.viewPort}
        animate={controls}
        initial={{ x: 0 }}
        custom={titleSize}
      >
        {children}
      </motion.div>
    </motion.div>
  )
}
