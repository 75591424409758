import { css } from '@emotion/core'
import { ThemeTypes } from '@/app/theme/theme'

export default {
  hero: (theme: ThemeTypes) => css`
    width: 100%;
    height: calc(100vh - ${theme.navigation.height});
    display: flex;
    overflow: hidden;
  `,
  gridContainer: css`
    width: 100%;
    display: flex;
    align-items: center;
  `,
  businessActions: (theme: ThemeTypes) => css`
    height: calc(100vh - ${theme.navigation.height});
    padding: 0px 16px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    // TODO get a better overlay color
    background: rgba(0, 0, 0, 0.4);
    @media (min-width: ${theme.mediaQueries.medium}px) {
      position: relative;
      width: unset;
      background: white;
    }
  `,
  actionsWrapper: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  landingPageWrapper: (theme: ThemeTypes) => css`
    background: ${theme.navigation.color};
    width: 100%;
  `,
}
