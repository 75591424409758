import { css } from '@emotion/core'

export default {
  paralaxSliderContainer: css`
    height: 100%;
  `,
  viewPort: css`
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
  `,
}
