import { ThemeTypes } from '@/app/theme/theme'
import { css } from '@emotion/core'
import { BackgroundColor } from './sideBySide'
import { theme } from '@/app/theme/theme'

const { mediaQueries } = theme

export default {
  sideBySideContainer: (
    theme: ThemeTypes,
    background: BackgroundColor,
    contentLocation: 'left' | 'right',
  ) => css`
    width: 100%;
    display: flex;
    flex-direction: ${contentLocation === 'left' ? 'column' : 'column-reverse'};
    background: ${theme[background]};
    position: relative;
    z-index: 10;
    @media (min-width: ${mediaQueries.small}px) {
      flex-direction: ${contentLocation === 'left' ? 'row' : 'row-reverse'};
    }
  `,
  contentContainer: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 64px 32px;
    position: relative;
    background: inherit;
    z-index: 10;
  `,
  componentContainer: css`
    display: flex;
    width: 100%;
    position: relative;
    background: inherit;
    z-index: 1;
  `,
}
