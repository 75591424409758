import { css } from '@emotion/core'

export default {
  masonGrid: (columnSize: number, rowSize: number, gap: number) => css`
    display: grid;
    grid-gap: ${gap}px;
    grid-template-rows: repeat(5, ${rowSize}px);
    grid-auto-flow: column;
    grid-auto-columns: ${columnSize / 2}px;
  `,
  gridItem: (image: string) => css`
    background-image: url(${image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
    grid-column: span 2;
  `,
  blocker: css`
    grid-column: span 1;
  `,
}
