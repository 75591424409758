import React, { FC, useContext } from 'react'
import { css } from '@emotion/core'
import { Text } from '@/app/atoms/text/text'
import styles from './styles'
import { useThemeHook } from '@/hooks/useThemeHook'
import { LayoutContext } from '@/app/organisms/layout/layout'

export type BackgroundColor = 'foreground' | 'background'
type SideBySideProps = {
  title?: string
  description?: string
  component: React.ReactElement
  componentTwo?: React.ReactElement
  background?: BackgroundColor
  contentLocation?: 'left' | 'right'
}

export const SideBySide: FC<SideBySideProps> = ({
  component,
  title,
  description,
  componentTwo,
  background = 'background',
  contentLocation = 'left',
}) => {
  const theme = useThemeHook()
  return (
    <section
      css={styles.sideBySideContainer(theme, background, contentLocation)}
    >
      <div css={styles.contentContainer}>
        {componentTwo ? (
          componentTwo
        ) : (
          <>
            <div
              css={css`
                padding-bottom: ${theme.spacing.x2};
              `}
            >
              <Text type="subTitle" alignment="center">
                {title}
              </Text>
            </div>
            <Text type="body" alignment="center">
              {description}
            </Text>
          </>
        )}
      </div>
      <div css={styles.componentContainer}>{component}</div>
    </section>
  )
}
