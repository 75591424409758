import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { LayoutContext } from '@/app/organisms/layout/layout'
import { useThemeHook } from '@/hooks/useThemeHook'
import { ParalaxSlider } from '@/app/molecules/paralaxSlider/paralaxSlider'
import { MasonGrid } from '@/app/organisms/masonGrid/masonGrid'
import { SideBySide } from '@/app/molecules/sideBySide/sideBySide'
import { motion, useViewportScroll, useTransform, m } from 'framer-motion'
import { Text } from '@/app/atoms/text/text'
import styles from './styles'
import { Button } from '@/app/atoms/button/button'
import { LandingPageContentQuery_Manual } from '../../../../manual-graphql-types'
import { Carousel } from '../carousel/carousel'
import Img from 'gatsby-image'
import css from '@emotion/css'

export const LandingPage = () => {
  const { isMobile } = useContext(LayoutContext)
  const theme = useThemeHook()
  const { scrollYProgress } = useViewportScroll()
  const scrollOffset = useTransform(scrollYProgress, y => y * 140)

  const landingPageContent: LandingPageContentQuery_Manual = useStaticQuery(graphql`
    query LandingPageContent {
      allSanityImageGallery {
        edges {
          node {
            images {
              asset {
                fixed(width: 556) {
                  ...GatsbySanityImageFixed_noBase64
                }
              }
            }
          }
        }
      }
      allSanityContactDetails {
        edges {
          node {
            id
            email
            phoneNumber
            name
          }
        }
      }
      allSanityLandingPage {
        edges {
          node {
            contactUsImage {
              asset {
                id
                fluid(maxWidth: 420) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            gallerySection {
              description
              title
            }
            whatWeDoSection {
              bullet1 {
                description
                title
              }
              bullet2 {
                description
                title
              }
              bullet3 {
                description
                title
              }
              bullet4 {
                description
                title
              }
              sectionInfo {
                description
                title
              }
            }
          }
        }
      }
    }
  `)

  const images = landingPageContent.allSanityImageGallery.edges[0].node.images
  const mappedImages = images.map(image => {
    const imageString = image.asset.fixed.src
    return imageString
  })
  const contactDetails = landingPageContent.allSanityContactDetails.edges[0]
  const sectionData = landingPageContent.allSanityLandingPage.edges[0].node
  const { contactUsImage, whatWeDoSection, gallerySection } = sectionData

  return (
    <motion.div css={styles.landingPageWrapper(theme)}>
      <section css={styles.hero(theme)}>
        <div css={styles.businessActions(theme)}>
          <motion.div css={styles.actionsWrapper} style={{ y: scrollOffset }}>
            <Text
              type={isMobile ? 'title' : 'heroTitle'}
              inverseColor={isMobile ? true : false}
            >
              Tactical Tile
            </Text>
            <Link to="/contact">
              <Button size={isMobile ? 'medium' : 'large'} style="primary">
                Get Quote
              </Button>
            </Link>
          </motion.div>
        </div>
        <div css={styles.gridContainer}>
          <ParalaxSlider>
            <MasonGrid
              minimum={50}
              gap={16}
              columnSize={256}
              rowSize={144}
              images={mappedImages}
            />
          </ParalaxSlider>
        </div>
      </section>
      <SideBySide
        background="foreground"
        componentTwo={
          <div
            css={css`
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
            `}
          >
            <Text type="subTitle" alignment="center">
              {gallerySection.title}
            </Text>
            <Text type="body" alignment="center">
              {gallerySection.description}
            </Text>
            <div
              css={css`
                width: 100%;
                display: flex;
                justify-content: center;
                padding: ${theme.spacing.x4};
              `}
            >
              <Link to="/work">
                <Button size="large">View Work</Button>
              </Link>
            </div>
          </div>
        }
        component={<Carousel height={516} images={mappedImages} />}
      />
      <SideBySide
        background="background"
        title={whatWeDoSection.sectionInfo.title}
        description={whatWeDoSection.sectionInfo.description}
        component={<div></div>}
        contentLocation="right"
      />
      <SideBySide
        background="foreground"
        title={'Contact Us'}
        description={'This descriptiong needs to be gone'}
        component={
          <Img
            style={{ width: '100%', height: '100%' }}
            fluid={contactUsImage.asset.fluid}
          />
        }
        componentTwo={
          <div
            css={css`
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
            `}
          >
            <div
              css={css`
                padding: ${theme.spacing.x1} ${'0px'};
              `}
            >
              <Text type="subTitle" alignment="center">
                Contact Us
              </Text>
            </div>
            <div
              css={css`
                padding: ${theme.spacing.x1} ${'0px'};
              `}
            >
              <Text type="body" alignment="center" weight="bold">
                You can reach us at:
              </Text>
            </div>
            <ul
              css={css`
                text-decoration: none;
                margin: 0;
                list-style-type: none;
                padding: 0;
              `}
            >
              <Text type="body" alignment="center">
                {contactDetails.node.phoneNumber}
              </Text>
              <Text type="body" alignment="center">
                {contactDetails.node.email}
              </Text>
            </ul>
            <div
              css={css`
                padding: ${theme.spacing.x4} ${'0px'};
                display: flex;
                width: 100%;
                justify-content: center;
              `}
            >
              <Link to="/contact">
                <Button size="large">Free Quote</Button>
              </Link>
            </div>
          </div>
        }
      />
    </motion.div>
  )
}
