import React, { FC } from 'react'
import Img from 'gatsby-image'
import styles from './styles'

type MasonGridProps = {
  images: string[]
  columnSize: number
  rowSize: number
  gap: number
  minimum?: number
}

export const MasonGrid: FC<MasonGridProps> = ({
  images,
  columnSize,
  rowSize,
  gap,
  minimum,
}) => {
  const filteredImages = () => {
    if (minimum) {
      let tempArray = [...images]
      while (minimum > tempArray.length) {
        return tempArray.concat(images)
      }
      return tempArray
    }
    return images
  }

  filteredImages().splice(1, 0, '')
  filteredImages().splice(3, 0, '')

  const getClass = (index: number, image: string) => {
    if (index === 1 || index === 3) {
      return styles.blocker
    }
    return styles.gridItem(image)
  }

  return (
    <div css={styles.masonGrid(columnSize, rowSize, gap)}>
      {filteredImages().map((image, index) => {
        return <div key={index} css={getClass(index, image)} />
      })}
    </div>
  )
}
